body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'determination';
    src: url('fonts/determination.woff') format('woff');
}

@font-face {
    font-family: 'mars';
    src: url('fonts/mars.woff') format('woff');
}

@font-face {
    font-family: 'undertale-ja';
    src: url('fonts/undertale-ja.woff') format('woff');
}

@font-face {
    font-family: 'sans-ja';
    src: url('fonts/sans-ja.woff') format('woff');
}

@font-face {
    font-family: 'papyrus-ja';
    src: url('fonts/tanuki.woff') format('woff');
}

@font-face {
    font-family: 'sans-en';
    src: url('fonts/sans-en.woff') format('woff');
}

@font-face {
    font-family: 'papyrus-en';
    src: url('fonts/papyrus-en.woff') format('woff');
}
